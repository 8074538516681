// const config = {
//     vk_group_url: "https://vk.com/thenewbotbandit",
//     vk_goto_bot_url: "https://vk.me/thenewbotbandit",
//     vk_group_id: 189055795
// };

const config = {
    testing: false,
    vk_group_url: "https://vk.com/botbandit",
    vk_goto_bot_url: "https://vk.me/botbandit",
    vk_goto: "https://vk.me/public",
    vk_group_id: 166948584,
    vk_app_id: 8008636,
    partners: {
        "0": "166948584",
        "1": "189055795",
        "2": "185015296",
        "3": "201232613",
        "4": "204531983"
    },
    scamReasons: {
        "0": [
            "заскамлена на авито",
            "потеряла данные от вк",
            "потеряла доступ к карте",
            "купила айфон c google play",
            "заскамлена на паспорт",
            "заскамлена на интимки",
            "заскамлена на нюдсы",
            "сказала данные карты по телефону",
            "скачала майнер на комп",
            "скачала читы на бравл старс",
            "перешла по фиш-ссылке",
            "заджиглена на авито",
            "заскамлена на акк вк",
            "заджиглена на акк вк",
            "заскамлена на карту с cvv",
            "заджиглена на карту с cvv",
            "заскамлена на паспорт",
            "заджиглена на паспорт",
            "обманута на паспорт",
            "заджиглен на нюдсы",
            "обманута на интимки"
        ],
        "1": [
            "заскамлен на авито",
            "потерял данные от вк",
            "потерял доступ к карте",
            "купил айфон c google play",
            "заскамлен на паспорт",
            "заскамлен на интимки",
            "купил мочу вместо пива",
            "сказал данные карты по телефону",
            "скачал майнер на комп",
            "скачал читы на бравл старс",
            "перешел по фиш-ссылке",
            "заджиглен на авито",
            "заскамлен на акк вк",
            "заджиглен на акк вк",
            "заскамлен на карту с cvv",
            "заджиглен на карту с cvv",
            "заскамлен на паспорт",
            "заджиглен на паспорт",
            "обманут на паспорт",
            "заджиглен на интимки",
            "обманут на интимки"
        ]
    }
};

export default config;