// REACT
import React, {Component, useEffect, useState, useContext, useRef} from 'react';

// VK
import bridge from '@vkontakte/vk-bridge';
// import Div from "@vkontakte/vkui/dist/components/Div/Div";
// import Index from "./panels/Index";
// import {isArraysIsEqu} from "./functions";
// import {Icon56CheckCircleOutline, Icon56CancelCircleOutline, Icon56ErrorOutline} from '@vkontakte/icons';
// import {blockInt} from "./functions";

// CSS
import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

// VK UI COMPONENTS
import {
    Button,
    Group,
    ModalPage,
    ModalRoot,
    Panel,
    PanelHeader,
    View,
    Placeholder,
    Separator,
    Header,
    RichCell,
    Avatar,
    Cell,
    Footer,
    CardGrid,
    Card,
    Title,
    Div,
    Caption, PullToRefresh
} from '@vkontakte/vkui';

// Icons
import {
    Icon28Message,
    Icon28WheelOutline,
    Icon28SmartphoneOutline,
    Icon28MessageAddBadgeOutline,
    Icon28LightbulbOutline,
    Icon28SmartphoneStarsOutline,
    Icon28StatisticsOutline,
    Icon28Game,
    Icon28MasksOutline, Icon28MoneyWadOutline
} from '@vkontakte/icons';

// My
import config from "./config";
import {isBonusAvalible, pmoney, sleep, usePar, checkErrors} from "./functions";
import {MainContext} from "./context";
import {apiGetScam, vkapiGetPlayers} from "./bbapi";

function Menu({id}) {
    const mainContext = useContext(MainContext);
    const user = mainContext.user;

    useEffect(() => {
        bridge.send('VKWebAppJoinGroup', {group_id: config.vk_group_id});
    }, []);

    //const [isFetching, setFetching] = useState(false);
    const [isFetching, setFetching] = usePar(mainContext, "isFetching", false);

    function activateFetching() {
        setFetching(true);
        mainContext.set();
    }

    async function updating() {
        const minTime = 600;
        const start = Date.now();

        async function extraFunc() {
            const current = Date.now();
            if (current - start < minTime) {
                await sleep(minTime - (current - start));
            }
        }

        if (!await mainContext.updateMenu(extraFunc)) {
            return;
        }

        setFetching(false);
        mainContext.set();
    }

    if (isFetching) {
        console.log("updating menu...");
        updating();
    }

    console.log("context", mainContext);

    function openBetsModal() {
        const modal = {id: "bets"};
        mainContext.setActiveModal(modal);
        mainContext.set();
    }

    function openScam() {
        function todo(context) {
            mainContext.updateScam(context, false);
        }

        mainContext.toDoAfter = todo;
        mainContext.setPopout(true);
        mainContext.set();
    }

    function openRoulette() {
        function todo(context) {
            mainContext.updateRoulette(context, false);
        }

        mainContext.toDoAfter = todo;
        mainContext.setPopout(true);
        mainContext.set();
    }

    return <Panel id={id}>
        <PanelHeader style={{opacity: 0}}>главное меню</PanelHeader>
        <PullToRefresh onRefresh={activateFetching} isFetching={isFetching}>
            <Group>
                <div style={{marginBottom: 16 + 22}}>
                    <Header mode="secondary" style={{padding: 0}}>
                        <div style={{
                            textAlign: "center",
                            width: document.documentElement.clientWidth
                        }}>{`привет, ${user.vk.first_name}, на счету у тя`}</div>
                    </Header>
                    <Title level="1" weight="bold"
                           style={{marginBottom: 16, textAlign: "center", fontSize: 36}}>{pmoney(user.bb.money)}</Title>
                </div>
                <Div className="bandit-menu-card-blocks-container">
                    <div className="bandit-menu-card"
                         onClick={() => {
                             openBetsModal();
                         }}>
                        <Icon28MoneyWadOutline width={32} height={32} className="bandit-menu-icon" />
                    </div>
                    <div className="bandit-menu-card bandit-menu-ml"
                         onClick={() => {
                             openRoulette();
                         }}>
                        <Icon28WheelOutline width={32} height={32} className="bandit-menu-icon"/>
                    </div>
                    <div className="bandit-menu-card bandit-menu-ml"
                         onClick={() => {
                             openScam();
                         }}>
                        <Icon28MasksOutline width={32} height={32} className="bandit-menu-icon"/>
                    </div>
                </Div>
                <Div className="bandit-menu-card-titles-container">
                    <Caption caps level="2" style={{width: "calc((100% - 2*8px)/3)"}}>
                        <div style={{textAlign: "center"}}>ставки</div>
                    </Caption>
                    <Caption caps level="2" style={{width: "calc((100% - 2*8px)/3)", marginLeft: 8}}>
                        <div style={{textAlign: "center"}}>джекпот рулетка</div>
                    </Caption>
                    <Caption caps level="2" style={{width: "calc((100% - 2*8px)/3)", marginLeft: 8}}>
                        <div style={{textAlign: "center"}}>скам</div>
                    </Caption>
                </Div>
            </Group>

            <Group>
                <Cell href={`https://vk.com/app6441755_-${config.partners[user.bb.active_partner]}`} expandable
                      before={<Icon28MessageAddBadgeOutline/>}>
                    Добавить бота в беседу
                </Cell>
                <Cell href={config.vk_goto + config.partners[user.bb.active_partner]} expandable before={
                    <Icon28Game/>
                }>
                    Перейти в бот бандит
                </Cell>
            </Group>
        </PullToRefresh>

        {/*{document.documentElement.clientHeight >= 568 && <Footer className="bandit-footer">BOT BANDIT 2021</Footer>}*/}
    </Panel>;
}

export default Menu;