// REACT
import React, {Component, useEffect, useState, useContext, useRef} from 'react';

// CSS
import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

// VK UI COMPONENTS
import {
    Button, Group, ModalPage, ModalRoot, Panel, PanelHeader, View, Placeholder, Separator
} from '@vkontakte/vkui';

// Icons
import {Icon56GestureOutline, Icon56DoNotDisturbOutline} from '@vkontakte/icons';

// My
import config from "./config";
import {getHashData} from "./functions";

function Login({id, loginType}) {
    const hashData = getHashData();

    let content;
    switch (loginType) {
        case "register":
            content = <Placeholder
                icon={<Icon56GestureOutline className="bandit-icon" />}
                header="у тебя ещё нет аккаунта в бот бандит"
                action={<Button href={config.vk_goto + config.partners[hashData.partner]} size="m" stretched>создать</Button>}
                style={{verticalAlign: "middle"}}
            >
                но это легко исправить! жду тебя тут после того, как ты пройдешь регистрацию и создашь своего человечка.
            </Placeholder>;
            break;
        case "ban":
            content = <Placeholder
                icon={<Icon56DoNotDisturbOutline className="bandit-icon" />}
                header="аккаунт заблокирован"
                style={{verticalAlign: "middle"}}
            >
                ты не можешь использовать это приложение, так как твой аккаунт бот бандит заблокирован.
            </Placeholder>;
            break;
    }

    return <Panel id={id}>
        <Group style={{padding: 0}}>
            <div style={{
                position: "absolute",
                top: 0
            }}>
                <div style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    height: document.documentElement.clientHeight
                }}>
                    {content}
                </div>
            </div>
        </Group>
    </Panel>;
}

export default Login;