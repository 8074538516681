// REACT
import React, {Component, useEffect, useState, useContext, useRef} from 'react';

// VK
import bridge from '@vkontakte/vk-bridge';
// import Div from "@vkontakte/vkui/dist/components/Div/Div";
// import Index from "./panels/Index";
// import {isArraysIsEqu} from "./functions";
// import {Icon56CheckCircleOutline, Icon56CancelCircleOutline, Icon56ErrorOutline} from '@vkontakte/icons';
// import {blockInt} from "./functions";

// CSS
import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

// VK UI COMPONENTS
import {
    Button,
    Group,
    ModalPage,
    ModalRoot,
    Panel,
    PanelHeader,
    View,
    Placeholder,
    Separator,
    Header,
    RichCell,
    Avatar,
    Cell, Footer, PanelHeaderBack, CardGrid, ContentCard, HorizontalCell, Div, SimpleCell, InfoRow, usePlatform
} from '@vkontakte/vkui';

// Icons
import {Icon28Message, Icon28WheelOutline, Icon56GestureOutline} from '@vkontakte/icons';

// My
import config from "./config";
import {pmoney, sleep} from "./functions";
import {getSexByVkSex} from "./functions";
import {MainContext} from "./context";

function getSecondsInt(end) {
    if (end < 0) {
        return end;
    }

    return Math.max(0, Math.floor(((end * 1000) - Date.now()) / 1000));
}

function getSecondsCaption(end) {
    if (end == -1 || end == 0) {
        return "—";
    }

    function getSecondsForm(sec) {
        return "сек";

        if (10 <= sec && sec <= 20) return "секунд";
        if (sec % 10 == 1) return "секунда";
        if (2 <= sec % 10 && sec % 10 <= 4) return "секунды";

        return "секунд";
    }

    const secInt = getSecondsInt(end);
    return `${secInt} ${getSecondsForm(secInt)}`;
}

function getPlayersCountCaption(count) {
    function getPlayersForm(sec) {
        if (10 <= sec && sec <= 20) return "человек";
        if (sec % 10 == 1) return "человек";
        if (2 <= sec % 10 && sec % 10 <= 4) return "человека";

        return "человек";
    }

    return `${count} ${getPlayersForm(count)}`;
}

function getMiddleMargin() {
    // 767 - padding 0
    // 768 - padding 8
    //console.log(document.documentElement.clientWidth);

    const leftMarPlatform = String(usePlatform()) == "ios" ? 4 : 8;
    const leftMarSizeX = document.documentElement.clientWidth >= 768 ? 8 : 0;
    const leftMar = leftMarPlatform + leftMarSizeX;
    //console.log(leftMar);

    const cardLength = (document.documentElement.clientWidth / 2) - leftMar;
    const cardProc = cardLength % 72;

    if (cardProc <= 36) {
        return 36 - cardProc;
    } else {
        return 72 + 36 - cardProc;
    }
}

function setWrapperItems(players, order) {
    const items = [];
    const orderArr = JSON.parse(order);

    if (players.length == 0) {
        for (let i = 0; i < 100; i++) {
            items.push({first_name: null, photo: null});
        }
    } else {
        for (let i = 0; i < 100; i++) {
            items.push(players[orderArr[i]]);
        }
    }

    return items;
}

function setWrapperActive(active, offset) {
    if (active) {
        return {
            transform: `translate(-${offset}px)`,
            transitionProperty: "transform",
            transitionDuration: "8s",
            transitionTimingFunction: "cubic-bezier(0.0125, 0.1, 0.1, 1)",
        };
    } else {
        return {
            transform: `translate(-${offset ? offset : getMiddleMargin()}px)`,
        };
    }
}

function getButtonStatus(players, id) {
    for (let item of players) {
        if (item.id == id) {
            return true;
        }
    }

    return false;
}

function calculateTranslate() {
    const leftMarPlatform = String(usePlatform()) == "ios" ? 4 : 8;
    const leftMarSizeX = document.documentElement.clientWidth >= 768 ? 8 : 0;
    const leftMar = leftMarPlatform + leftMarSizeX;

    const cardLength = (document.documentElement.clientWidth / 2) - leftMar;

    const translate = (leftMar + 72 * 79) - (document.documentElement.clientWidth / 2);
    return translate;
}

const testing = config.testing;

function Roulette({id}) {
    const mainContext = useContext(MainContext);

    let headerText = "ожидание игроков...";
    switch (mainContext.rouletteStatus) {
        case 1:
        case 2:
            headerText = "определение победителя...";
            break;
    }

    const wrapper = mainContext.rouletteStatus == 2 || mainContext.rouletteStatus == 3 ? setWrapperActive(true, calculateTranslate() + mainContext.roulette.offset) : setWrapperActive(false, getMiddleMargin());
    let balance = mainContext.roulette.money;

    switch (mainContext.rouletteStatus) {
        case 0:
            if (mainContext.roulette.start * 1000 > Date.now() || mainContext.roulette.start == 0) {
                if (mainContext.currentPanel == "roulette" && (mainContext.activeModal && mainContext.activeModal.id == null)) {
                    mainContext.rouletteUpdTimer = setTimeout(() => mainContext.updateRoulette(mainContext, true), 1000);
                    console.log("хуярим", mainContext.rouletteUpdTimer);
                }
            } else {
                mainContext.rollRoulette(mainContext, mainContext.rouletteTime);
            }

            break;
        case 1:
            if (mainContext.currentPanel == "roulette" && (mainContext.activeModal && mainContext.activeModal.id == null)) {
                mainContext.rollRoulette(mainContext, mainContext.rouletteTime);
            }

            break;
        case 2:
            if (mainContext.roulette.winner_id == mainContext.roulette.id) {
                balance -= mainContext.roulette.pot;
            }

            function openResultModal() {
                mainContext.setActiveModal({id: "roulette_result"});
                mainContext.set();
            }

            mainContext.setRouletteStatus(3);
            mainContext.rouletteUpdTimer = setTimeout(() => openResultModal(), 8000);
            break;
        case 3:
            // открытая модалка
            break;
    }

    function openBetModal() {
        bridge.send("VKWebAppTapticImpactOccurred", {"style": "heavy"});

        console.log("type", mainContext.user.bb.type);
        if (mainContext.user.bb.type != -1) {
            const modal = {id: "roulette_bet"};
            mainContext.setActiveModal(modal);
            mainContext.rset();
        } else {
            const modal = {id: "roulette_warn"};
            mainContext.setActiveModal(modal);
            mainContext.rset();
        }
    }

    let isButtonDisabled = false;
    if ((mainContext.rouletteStatus == 0 && getSecondsInt(mainContext.roulette.start) <= 10 && mainContext.roulette.start > 0) || mainContext.rouletteStatus > 0) {
        isButtonDisabled = true;
    }

    return <Panel id={id}>
        <PanelHeader
            left={<PanelHeaderBack onClick={() => mainContext.goBackNow()}/>}
        >
            рулетка
        </PanelHeader>
        <Group header={<Header mode="secondary">{headerText}</Header>}>
            <div
                style={{left: (document.documentElement.clientWidth / 2) - 1}}
                className="bandit-roulette-line"
            />
            <div className="bandit-roulette-wrapper">
                <div style={wrapper} className="bandit-roulette-wrapper-list">
                    {mainContext.roulette.players.map((id, index) => {
                        let vkName, vkPhoto;

                        if (testing) {
                            vkName = "p" + id;
                            vkPhoto = false;
                        } else {
                            if (id == 0) {
                                vkName = " ";
                                vkPhoto = false;
                            } else {
                                if (mainContext.vk_players[id]) {
                                    vkName = mainContext.vk_players[id].first_name;
                                    vkPhoto = mainContext.vk_players[id].photo_100;
                                } else {
                                    //console.log("id", id, "player", mainContext.vk_players[id], mainContext.vk_players);

                                    vkName = "ERROR";
                                    vkPhoto = false;
                                }
                            }
                        }

                        return (
                            <HorizontalCell
                                key={index}
                                href={`https://vk.com/id${id}`}
                                header={vkName}
                            >
                                <Avatar size={56} src={vkPhoto}/>
                            </HorizontalCell>
                        )
                    })}
                </div>
            </div>
            <Div>
                <Button
                    size="m"
                    disabled={isButtonDisabled}
                    stretched
                    style={{marginTop: 8}}
                    onClick={() => openBetModal()}
                >поставить</Button>
            </Div>
        </Group>
        <Group>
            <div style={{display: "flex"}}>
                <div style={{width: "50%"}}>
                    <SimpleCell>
                        <InfoRow header="ставка">
                            {pmoney(mainContext.roulette.bet)}
                        </InfoRow>
                    </SimpleCell>
                </div>
                <div style={{width: "50%"}}>
                    <SimpleCell>
                        <InfoRow header="баланс">
                            {pmoney(balance)}
                        </InfoRow>
                    </SimpleCell>
                </div>
            </div>
            <div style={{display: "flex"}}>
                <div style={{width: "50%"}}>
                    <SimpleCell>
                        <InfoRow header="банк">
                            {pmoney(mainContext.roulette.pot)}
                        </InfoRow>
                    </SimpleCell>
                </div>
                <div style={{width: "50%"}}>
                    <SimpleCell>
                        <InfoRow header="участвуют в розыгрыше">
                            {getPlayersCountCaption(mainContext.roulette.players_count)}
                        </InfoRow>
                    </SimpleCell>
                </div>
            </div>
            <div style={{display: "flex"}}>
                <div style={{width: "50%"}}>
                    <SimpleCell>
                        <InfoRow header="до старта">
                            {getSecondsCaption(mainContext.roulette.start)}
                        </InfoRow>
                    </SimpleCell>
                </div>
                <div style={{width: "50%"}}>
                    <SimpleCell>
                        <InfoRow header="шанс выигрыша">
                            {mainContext.roulette.chance > 0 ? mainContext.roulette.chance + "%" : "—"}
                        </InfoRow>
                    </SimpleCell>
                </div>
            </div>
        </Group>
    </Panel>;
}

export default Roulette;