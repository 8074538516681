import React, {useEffect, useRef, useState} from 'react';

// CSS
import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

import {
    Icon56CancelCircleOutline,
    Icon56CheckCircleOutline,
    Icon56ErrorOutline,
    Icon56RecentOutline,
    Icon56DoNotDisturbOutline,
    Icon56MoneyTransferOutline,
    Icon24PaintRollerOutline,
    Icon28DrillOutline,
    Icon28CupOutline,
    Icon24SadFaceOutline,
    Icon28WheelOutline, Icon56BlockOutline
} from "@vkontakte/icons";
import {
    Button,
    ModalCard,
    Input,
    useAdaptivity,
    usePlatform,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    Group,
    CellButton,
    Placeholder,
    FormItem
} from "@vkontakte/vkui";
import bridge from "@vkontakte/vk-bridge";

import {Icon32MasksOutline, Icon24Dismiss} from '@vkontakte/icons';
import {parse_str, pmoney, umoney} from "./functions";
import {func} from "prop-types";
import config from "./config";

const testing = config.testing;

function CustomModalCard({id, onClose, header, subheader, icon}) {
    return <ModalCard
        id={id}
        onClose={() => {
            onClose();
        }}
        icon={icon}
        header={header}
        subheader={subheader}
        actions={
            <Button size="m" mode="primary" onClick={() => {
                onClose();
            }}>
                окей
            </Button>
        }
    ></ModalCard>;
}

function BetsModal({id, onClose}) {
    return <CustomModalCard
        id={id}
        onClose={onClose}
        header="совсем скоро..."
        subheader="ставки на спорт в игровой валюте на данный момент в разработке, и уже скоро будут доступны"
        icon={<Icon28DrillOutline width={56} height={56} className="bandit-icon"/>}
    />;
}

function ScamModal({id, onClose, setSnack, refLink}) {
    const icon = <Icon32MasksOutline width={56} height={56} className="bandit-icon"/>;

    return <ModalCard
        id={id}
        icon={icon}
        header="Твоя скам-ссылка"
        subheader={
            "Когда человек, не играющий в бота, перейдет по этой ссылке, он будет заскамлен тобой: ты получишь с него рандомное количество денег, а также он появится в списке твоих мамонтов."
            // <span>
            //     <p>Когда человек перейдет по этой ссылке, он будет заскамлен тобой: ты получишь с него деньги, а также он появится в списке твоих мамонтов.</p>
            //     <p>Подробную информацию о том, кто может стать мамонтом, а также по какому алгоритму начисляются деньги, можно найти, нажав кнопку "информация".</p>
            // </span>
        }
        onClose={() => {
            onClose();
        }}
        actions={
            <Button
                size="l"
                mode="primary"
                onClick={() => {
                    bridge.send("VKWebAppTapticImpactOccurred", {"style": "heavy"});
                    bridge.send('VKWebAppCopyText', {text: refLink});
                    setSnack(true);
                    onClose();
                }}
            >
                Скопировать
            </Button>
        }
    >
        <Input value={refLink}/>
    </ModalCard>
}

function RouletteBetModal({id, onClose, money, mainContext}) {
    function onInput(event) {
        let input = event.target.value;
        input = +umoney(input);

        if (input >= Number.MAX_SAFE_INTEGER) {
            return;
        }

        mainContext.rouletteCurrentBet = pmoney(input);

        setInputStatus("default");
        mainContext.set();
    }

    function set_x2() {
        let input = +umoney(mainContext.rouletteCurrentBet);
        if (input >= Number.MAX_SAFE_INTEGER / 2) {
            return;
        }

        mainContext.rouletteCurrentBet = pmoney(input * 2);

        setInputStatus("default");
        mainContext.set();
    }

    function set_x05() {
        let input = +umoney(mainContext.rouletteCurrentBet);
        mainContext.rouletteCurrentBet = pmoney(input / 2);

        setInputStatus("default");
        mainContext.set();
    }

    const [inputStatus, setInputStatus] = useState("default");

    const textInput = React.createRef();
    function clearAnimation() {
        if (textInput.current) {
            textInput.current.classList.remove("bandit-roulette-input-bad");
            console.log("remove anim", textInput.current);
        }

    }
    function setAnimation() {
        if (textInput.current) {
            textInput.current.classList.add("bandit-roulette-input-bad");
            console.log("set anim", textInput.current);
        }
    }

    useEffect(() => {
        setTimeout(clearAnimation, 500);
    });

    // if (inputStatus == "error") {
    //     clearAnimation();
    // }

    function onButtonClick() {
        let input = +umoney(mainContext.rouletteCurrentBet);

        if (input > money || input <= 0) {
            setInputStatus("error");
            setAnimation();
            //mainContext.rouletteCurrentBet = pmoney(money);
            mainContext.set();

            return;
        }

        mainContext.setActiveModal({id: null});
        mainContext.updateRoulette(mainContext, true, input);
    }

    return <ModalCard
        id={id}
        header="Введи ставку"
        onClose={() => {
            onClose();
        }}
        actions={
            <Button size="m" mode="primary" onClick={() => {
                onButtonClick();
            }}>
                поставить
            </Button>
        }
    >
        <FormItem
            top={`Текущая ставка (баланс — ${pmoney(money)})`}
            style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
            status={inputStatus}
        >
            <Input
                value={mainContext.rouletteCurrentBet}
                align="center"
                //className={inputStatus == "error" ? `bandit-roulette-input bandit-roulette-input-bad` : "bandit-roulette-input"}
                className="bandit-roulette-input"
                onInput={onInput}
                inputmode="numeric"
                getRootRef={textInput}
            />
        </FormItem>
        <div style={{display: "flex", marginTop: 6}}>
            <Button stretched mode="outline" style={{marginRight: 4}} onClick={() => set_x2()}>x2</Button>
            <Button stretched mode="outline" style={{marginLeft: 4}} onClick={() => set_x05()}>x0.5</Button>
        </div>
    </ModalCard>;
}

function get_form_of_chel(n) {
    if (10 <= n && n <= 20) {
        return `участвовало ${n} человек`;
    }

    switch (n % 10) {
        case 1:
            return `участвовал ${n} человек`;
        case 2:
        case 3:
        case 4:
            return `участвовало ${n} человека`;
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 0:
            return `участвовало ${n} человек`;
    }

    return `участвовало ${n} человек`;
}

function RouletteResultModal({id, onClose, context}) {
    let header;
    let subheader;
    let icon;

    function get_win(sex) {
        switch (sex) {
            case 0: return "выиграл(а)";
            case 1: return "выиграла";
            case 2: return "выиграл";
        }
    }

    function get_lose(sex) {
        switch (sex) {
            case 0: return "проиграл(а)";
            case 1: return "проиграла";
            case 2: return "проиграл";
        }
    }

    function get_win2(sex) {
        switch (sex) {
            case 0: return "победил(а)";
            case 1: return "победила";
            case 2: return "победил";
        }
    }

    console.log(context, context.roulette);
    if (context.roulette.is_player) {
        if (context.roulette.winner_id == context.roulette.id) {
            header = `ура! ты ${get_win(context.user.vk.sex)} ${pmoney(context.roulette.pot)}!`;
            subheader = `в розыгрыше ${get_form_of_chel(context.roulette.players_count)}. шанс выиграть был ${context.roulette.chance}%.`;
            icon = <Icon28CupOutline width={56} height={56} className="bandit-icon" />;
        } else {
            header = `к сожалению ты ${get_lose(context.user.bb.sex) ? "проиграл" : "проиграла"}`;
            subheader = `сумма в банке составляла ${pmoney(context.roulette.pot)}. шанс выиграть был ${context.roulette.chance}%.`;
            icon = <Icon24SadFaceOutline width={56} height={56} className="bandit-icon" />;
        }
    } else {
        if (testing) {
            header = `победил Бебрик!`;
        } else {
            header = `${get_win2(context.vk_players[context.roulette.winner_id].sex) ? "победил" : "победила"} ${context.vk_players[context.roulette.winner_id].first_name}!`;
        }

        subheader = `сумма в банке составляла ${pmoney(context.roulette.pot)}.`;
        icon = <Icon28WheelOutline width={56} height={56} className="bandit-icon" />;
    }

    return <ModalCard
        id={id}
        onClose={() => {
            onClose();
        }}
        icon={icon}
        header={header}
        subheader={subheader}
        actions={
            <Button size="m" mode="primary" onClick={() => {
                onClose();
            }}>
                окей
            </Button>
        }
    ></ModalCard>;
}

function RouletteWarnModal({id, onClose}) {
    return <CustomModalCard
        id={id}
        onClose={onClose}
        header="тебе запрещено играть здесь"
        subheader="из-за того, что у тебя на аккаунте предупреждение, ты не можешь играть в джекпот-рулетку."
        icon={<Icon56BlockOutline className="bandit-icon"/>}
    />;
}

export {BetsModal, ScamModal, RouletteBetModal, RouletteResultModal, RouletteWarnModal};