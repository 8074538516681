import {func} from "prop-types";

function getHashData() {
    const res = {
        scam: undefined,
        partner: "0",
        start: undefined
    };

    if (!window.location.hash) {
        return res;
    }

    function urldecode(url) {
        return decodeURIComponent(url.replace(/\+/g, ' '));
    }

    if (window.location.hash.slice(1, 2) == 's') {
        if (window.location.hash.indexOf('p') == -1) {
            res.scam = window.location.hash.slice(2);
        } else {
            res.scam = window.location.hash.slice(2, window.location.hash.indexOf('p'));
            res.partner = window.location.hash.slice(window.location.hash.indexOf('p') + 1);
        }

        return res;
    }

    try {
        // console.log("HASH", window.location.hash);
        // console.log("HASH without #", window.location.hash.slice(1));
        // console.log("DECODED", urldecode(window.location.hash.slice(1)));
        const dopData = JSON.parse(urldecode(window.location.hash.slice(1)));

        if (dopData.start) {
            switch (dopData.start) {
                case "bonus":
                    res.start = "notify";
                    break;
                case "scam":
                    res.start = "scam";
                    break;
            }
        }
    } catch (error) {
        console.log(error);
    }

    return res;
}

function pmoney(money, noDollar = false) {
    if (money >= 0) {
        let strMoney = String(money);
        let result = "";

        while (strMoney.length > 3)
        {
            result = "." + strMoney.slice(-3) + result;
            strMoney = strMoney.slice(0, -3);
        }

        if (!noDollar) {
            result = "$" + strMoney + result;
        } else {
            result = strMoney + result;
        }

        return result;
    } else {
        return "ERROR";
    }
}

function getSexByVkSex(vkSex) {
    switch (vkSex) {
        case 1:  return 0;
        case 2:  return 1;
        default: return 1;
    }
}

function parse_str(str, array) {	// Parses the string into variables
    //
    // +   original by: Cagri Ekin
    // +   improved by: Michael White (http://crestidg.com)

    var glue1 = '=';
    var glue2 = '&';

    var array2 = str.split(glue2);
    var array3 = [];
    for(var x=0; x<array2.length; x++){
        var tmp = array2[x].split(glue1);
        array3[unescape(tmp[0])] = unescape(tmp[1]).replace(/[+]/g, ' ');
    }

    if(array){
        array = array3;
    } else{
        return array3;
    }
}

function isBonusAvalible(last) {
    const pass = Math.floor((Date.now() - (last * 1000)) / 1000);
    return pass >= 86400;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function usePar(mainContext, name, defaultValue) {
    const first = mainContext[name] === undefined ? defaultValue : mainContext[name];
    function second(value) {
        mainContext[name] = value;
        //mainContext.set(mainContext);
    }

    return [first, second];
}

function checkErrors(apires, context) {
    if (apires.error) {
        switch (apires.errno) {
            case 1:
                context.setLoginType("register");
                context.goForward("login");
                context.setPopout(false);
                context.rset();

                return false;
            case 2:
                context.setLoginType("ban");
                context.goForward("login");
                context.setPopout(false);
                context.rset();

                return false;
        }
    }

    return true;
}

function umoney(str) {
    return str.replace(/[^+\d]/g, '');
}

export {pmoney, getSexByVkSex, parse_str, isBonusAvalible, getHashData, sleep, usePar, checkErrors, umoney};