// REACT
import React, {Component, useEffect, useState, useContext, useRef} from 'react';
import {parse_str, getHashData, usePar, checkErrors, sleep} from "./functions";
import {
    apiGetRoulette, apiGetRouletteResult,
    apiGetScam,
    apiInit,
    apiRouletteBet,
    apiUpdateMenu,
    vkapiGetPlayers,
    vkapiGetShortLink
} from "./bbapi";
import Login from './Login.js';

// CSS
import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

// import InterfaceBlock from "./InterfaceBlock";
import Menu from "./Menu";
import Scam from "./Scam";

// VK UI COMPONENTS
import {
    AdaptivityProvider,
    AppRoot, Button,
    ConfigProvider, Group, ModalRoot, Panel, ScreenSpinner, View, Root
} from '@vkontakte/vkui';
//import {ModalLose, ModalNoMoney, ModalWin} from "./Modals";
import bridge from "@vkontakte/vk-bridge";

//import config from "./config";
import {MainContext} from "./context";
import {BetsModal, RouletteBetModal, ScamModal, RouletteResultModal, RouletteWarnModal} from "./Modals";
import config from "./config";
import {func} from "prop-types";
import Roulette from "./Roulette";
import {withDOM} from "@vkontakte/vkui/dist/lib/dom";


const testing = config.testing;

// функция блока интерфейса
function blockInterface(setIsInterfaceBlockedRef) {
    setIsInterfaceBlockedRef.current(true);
    setTimeout(() => setIsInterfaceBlockedRef.current(false), 1000);
}

const testUser = {
    "id": 494075,
    "first_name": "Ирина",
    "last_name": "Денежкина",
    "sex": 1,
    "city": {
        "id": 2,
        "title": "Санкт-Петербург"
    },
    "country": {
        "id": 1,
        "title": "Россия"
    },
    "bdate": "10.4.1990",
    "photo_100": "https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg",
    "photo_200": "https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg",
    "timezone": 3
};

function App() {
    // блокировка интерфейса
    // const [isInterfaceBlocked, setIsInterfaceBlocked] = useState(false);
    // const setIsInterfaceBlockedRef = useRef(setIsInterfaceBlocked);

    // создаём контекст с данными пользователя
    const [mainContext, setMainContext] = useState({});
    mainContext.set = () => setMainContext(Object.assign({}, mainContext));

    const setMainContextRef = useRef(setMainContext);
    setMainContextRef.current = mainContext.set;//setMainContext;
    mainContext.rset = () => {
        if (mainContext.rouletteUpdTimer) {
            console.log("clear", mainContext.rouletteUpdTimer);
            clearTimeout(mainContext.rouletteUpdTimer);
        }

        mainContext.set();
        //setMainContextRef.current();
    }

    // setMainContextRef.current.f = setMainContext;
    // setMainContextRef.current.i++;

    // текущая панель
    //const [currentPanel, setCurrentPanel] = useState("loading");
    const [currentPanel, setCurrentPanel] = usePar(mainContext, "currentPanel", "loading")

    // текущий попаут
    //const [currentPopout, setCurrentPopout] = useState(<ScreenSpinner size='large'/>);
    const [currentPopout, setCurrentPopout] = usePar(mainContext, "currentPopout", <ScreenSpinner size='large'/>);

    function setDefPopoutStatus(status) {
        if (status) {
            setCurrentPopout(<ScreenSpinner size='large'/>);
        } else {
            setCurrentPopout(null);
        }
    }

    mainContext.setPopout = setDefPopoutStatus;

    // переменная для типа логина
    //const [loginType, setLoginType] = useState(null);
    const [loginType, setLoginType] = usePar(mainContext, "setLoginType", null);

    mainContext.setLoginType = setLoginType;

    // поддержка ios swipe back
    //const [history, setHistory] = useState([]);
    const [history, setHistory] = usePar(mainContext, "setHistory", [])

    function goForward(toPanel) {
        const copyOfHistory = [...history];
        copyOfHistory.push(toPanel);

        if (toPanel === 'menu' || toPanel === 'login' || toPanel === 'loading') {
            bridge.send('VKWebAppDisableSwipeBack');
        }

        setCurrentPanel(toPanel);
        setHistory(copyOfHistory);
    }

    function goBack() {
        const copyOfHistory = [...history];
        copyOfHistory.pop();

        const toPanel = copyOfHistory[copyOfHistory.length - 1];
        if (toPanel === 'menu' || toPanel === 'login' || toPanel === 'loading') {
            bridge.send('VKWebAppEnableSwipeBack');
        }

        setCurrentPanel(toPanel);
        setHistory(copyOfHistory);
    }

    function goBackNow() {
        goBack();
        mainContext.rset();
    }

    mainContext.goForward = goForward;
    mainContext.goBack = goBack;
    mainContext.goBackNow = goBackNow;

    // обновление бб инфы
    async function updateMenu(extraFunc = false) {
        const apires = await apiUpdateMenu();
        let ret = checkErrors(apires, mainContext);

        if (extraFunc) {
            await extraFunc();
        }

        mainContext.user.bb = apires;

        return ret;
    }

    mainContext.updateMenu = updateMenu;

    // обновление скама
    async function updateScam(context, isUpdate = false, extraFunc = false) {
        const apires = await apiGetScam();
        let ret = checkErrors(apires, context);

        if (ret) {
            context.scam = apires;
            const players = apires.mamonts.map((value) => value.id).concat(apires.top.map((value) => value.id));

            if (!testing) {
                const vkapires_players = await vkapiGetPlayers(context.vkToken, players);
                context.vk_players = vkapires_players;

                const vkapires_link = await vkapiGetShortLink(context.vkToken, context.user.bb.id, context.user.bb.partner_id);
                context.scam.link = vkapires_link;
            } else {
                context.scam.link = "https://bebra.com";
            }

            if (!isUpdate) {
                context.setPopout(false);
                context.goForward("scam");
            }

            if (extraFunc) {
                await extraFunc();
            }

            context.set();
        }

        return ret;
    }

    mainContext.updateScam = updateScam;

    // управление снэкбаром на скаме
    const [scamSnackBar, setScamSnackBar] = usePar(mainContext, "scamSnackBar", false);
    mainContext.setScamSnackBar = setScamSnackBar;

    // рулетка
    const [rouletteTime, setRouletteTime] = usePar(mainContext, "rouletteTime", false);
    mainContext.rouletteTime = rouletteTime;
    mainContext.setRouletteTime = setRouletteTime;

    const [rouletteStatus, setRouletteStatus] = usePar(mainContext, "rouletteStatus", 0);
    mainContext.rouletteStatus = rouletteStatus;
    mainContext.setRouletteStatus = setRouletteStatus;

    // обновление рулетки
    async function updateRoulette(context, isUpdate = false, bet = undefined) {
        const apires = bet ? await apiRouletteBet(bet) : await apiGetRoulette();
        let ret = checkErrors(apires, context);

        if (ret) {
            context.user.bb.money = apires.money;
            const players = apires.players;

            if (!testing) {
                const vkapires_players = await vkapiGetPlayers(context.vkToken, players);
                console.log("vk res", vkapires_players);
                context.vk_players = vkapires_players;
            }

            console.log("bbbr", apires, apires.start);

            if (!isUpdate) {
                context.setPopout(false);
                context.goForward("roulette");
                context.setRouletteStatus(0);
            } else {
                if (context.roulette.start > 0 && context.roulette.start != apires.start && bet === undefined) {
                    context.setRouletteStatus(1);
                    context.set(mainContext);

                    console.log("лазунчик", context.roulette.start, apires.start);
                    return;
                }
            }

            context.roulette = apires;

            context.setRouletteTime(apires.start - 5);
            context.set(mainContext);
        }

        return ret;
    }

    async function rollRoulette(context, time) {
        const apires = await apiGetRouletteResult(time);
        console.log(apires);
        let ret = checkErrors(apires, context);

        if (apires.errno == 801) {
            await sleep(500);
            mainContext.setRouletteStatus(1);
            context.set(mainContext);

            return;
        }

        if (ret) {
            context.roulette = apires;
            context.user.bb.money = apires.money;
            const players = apires.players;

            if (!testing) {
                const vkapires_players = await vkapiGetPlayers(context.vkToken, players);
                console.log("vk res", vkapires_players);
                context.vk_players = vkapires_players;
            }

            context.setRouletteStatus(2);

            context.set(mainContext);
        }

        return ret;
    }

    mainContext.updateRoulette = updateRoulette;
    mainContext.rollRoulette = rollRoulette;

    const debugRef = React.createRef();

    // ставим тему и забираем данные с VK Bridge
    // вызывается всего 1 разавс
    useEffect(() => {
        // подписываемся на изменение темы
        bridge.subscribe(({detail: {type, data}}) => {
            if (type === 'VKWebAppUpdateConfig') {
                const schemeAttribute = document.createAttribute('scheme');
                schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
                document.body.attributes.setNamedItem(schemeAttribute);
            }

            if (type === 'VKWebAppViewRestore') {
                if (currentPanel == 'login') {
                    async function fetchData() {
                        if (await updateMenu()) {
                            setLoginType(null);
                            goForward("menu");
                        }
                    }

                    fetchData();
                }
            }
        });

        async function fetchData() {
            console.log("url", window.location.href);

            debugRef.current.value = "1";

            const hashData = getHashData();
            debugRef.current.value = "2";
            const apires = await apiInit({scam: hashData.scam});
            debugRef.current.value = "3";

            console.log("apires", apires);

            const user = {};
            user.bb = apires;

            user.vk = testing ? testUser : await bridge.send('VKWebAppGetUserInfo');
            debugRef.current.value = "4";
            user.vk.notify = parse_str(window.location.search).vk_are_notifications_enabled === "1";

            mainContext.user = user;

            debugRef.current.value = "5";
            if (!testing) {
                const vkToken = await bridge.send("VKWebAppGetAuthToken", {"app_id": config.vk_app_id, "scope": ""});
                mainContext.vkToken = vkToken.access_token;
            }
            debugRef.current.value = "6";

            if (apires.error) {
                switch (apires.errno) {
                    case 1:
                        setLoginType("register");
                        goForward("login");
                        break;
                    case 2:
                        setLoginType("ban");
                        goForward("login");
                        break;
                }
            } else {
                goForward("menu");
            }

            setDefPopoutStatus(false);

            mainContext.set();
        }

        fetchData();
    }, []);

    // модалки
    //const [activeModal, setActiveModal] = useState({id: null});
    const [activeModal, setActiveModal] = usePar(mainContext, "activeModal", {id: null});
    mainContext.setActiveModal = setActiveModal;
    mainContext.activeModal = activeModal;

    function onModalClose() {
        setActiveModal({id: null});
        mainContext.set();
    }

    function onResModalClose() {
        mainContext.roulette.start = 0;
        mainContext.roulette.bet = 0;
        mainContext.roulette.pot = 0;
        mainContext.roulette.players_count = 0;
        mainContext.roulette.chance = 0;
        mainContext.roulette.players = [];

        for (let i = 0; i < 100; i++) {
            mainContext.roulette.players.push(0);
        }

        mainContext.setRouletteStatus(0);
        onModalClose();
    }

    console.log("render", mainContext);

    // создание функции, c отложенным действием
    if (mainContext.toDoAfter) {
        mainContext.toDoAfter(mainContext);
        mainContext.toDoAfter = null;
        mainContext.rset();
    }

    const [rouletteCurrentBet, setRouletteCurrentBet] = usePar(mainContext, "rouletteCurrentBet", "$1.000");
    mainContext.rouletteCurrentBet = rouletteCurrentBet;
    mainContext.setRouletteCurrentBet = setRouletteCurrentBet;

    return (
        <MainContext.Provider value={mainContext}>
            <ConfigProvider isWebView={true}>
                {/*<InterfaceBlock enabled={isInterfaceBlocked}/>*/}
                <AdaptivityProvider>
                    <AppRoot activeView="main">
                        <View
                            activePanel={currentPanel}
                            popout={currentPopout}
                            history={activeModal.id === null ? history : []}
                            onSwipeBack={goBackNow}
                            modal={<ModalRoot activeModal={activeModal.id} onClose={onModalClose}>
                                <BetsModal
                                    id="bets"
                                    onClose={onModalClose}
                                />
                                <ScamModal
                                    id="scam"
                                    onClose={onModalClose}
                                    setSnack={setScamSnackBar}
                                    refLink={mainContext.scam && mainContext.scam.link}
                                />
                                <RouletteBetModal
                                    id="roulette_bet"
                                    onClose={onModalClose}
                                    money={mainContext.user && mainContext.user.bb.money}
                                    mainContext={mainContext}
                                />
                                <RouletteResultModal
                                    id="roulette_result"
                                    onClose={onResModalClose}
                                    context={mainContext}
                                />
                                <RouletteWarnModal
                                    id="roulette_warn"
                                    onClose={onModalClose}
                                    context={mainContext}
                                />
                            </ModalRoot>}
                        >
                            <Panel id="loading">
                                <input ref={debugRef} style={{
                                    position: "absolute",
                                    top: 100,
                                    zIndex: 100
                                }} />
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    background: "red",
                                    position: "absolute"
                                }}/>
                            </Panel>
                            <Login id="login" loginType={loginType}/>
                            <Menu id="menu"/>
                            <Scam id="scam"/>
                            <Roulette id="roulette"/>
                        </View>
                    </AppRoot>
                </AdaptivityProvider>
            </ConfigProvider>
        </MainContext.Provider>
    );
}

export default App;