import bridge from "@vkontakte/vk-bridge";
import config from "./config";

async function bbapiRequest({method, data = {}}) {
    data.url = window.location.href;

    try {
        const response = await fetch(`https://botbandit.ru/api/${method}.php`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const res = await response.json();
        if (res.error) {
            switch (res.errno) {
                case 1:
                case 2:
                    return res;
                case 10000:
                    console.log(res.errno, res.error);
                    return null;
                default:
                    return res;
            }
        }

        return res;
    } catch (error) {
        console.log("bot bandit api error", error);
    }
}

async function apiInit({scam = false}) {
    const data = {};
    if (scam) {
        data.scammer_id = scam;
    }

    return await bbapiRequest({method: "init", data});
}

async function apiUpdateMenu() {
    return await bbapiRequest({method: "update-menu", data: {}});
}

async function apiGetScam() {
    return await bbapiRequest({method: "get-scam-info", data: {}});
}

async function apiGetRoulette() {
    return await bbapiRequest({method: "get-jackpot-info", data: {}});
}

async function apiRouletteBet(bet) {
    return await bbapiRequest({method: "jackpot-bet", data: {bet}});
}

async function apiGetRouletteResult(time) {
    return await bbapiRequest({method: "get-jackpot-result", data: {time}});
}

async function vkapiGetPlayers(token, players) {
    const resp = await bridge.send("VKWebAppCallAPIMethod", {
        "method": "users.get",
        "params": {
            "user_ids": players.join(","),
            "fields": "sex,photo_100",
            "v": "5.131",
            "access_token": token
        }
    });

    const res = {};
    for (let value of resp.response) {
        res[value.id] = value;
    }

    return res;
}

async function vkapiGetShortLink(token, player_id, partner_id) {
    const link = `https://vk.com/app${config.vk_app_id}#s${player_id}p${partner_id}`;
    const res = await bridge.send("VKWebAppCallAPIMethod", {
        "method": "utils.getShortLink",
        "params": {
            "url": link,
            "v": "5.131",
            "access_token": token
        }
    });

    console.log("short link", res);
    return res.response.short_url;
}

export {bbapiRequest, apiInit, apiUpdateMenu, apiGetScam, apiGetRoulette, apiRouletteBet, vkapiGetPlayers, vkapiGetShortLink, apiGetRouletteResult};