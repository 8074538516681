// REACT
import React, {Component, useEffect, useState, useContext, useRef} from 'react';

// VK
import bridge from '@vkontakte/vk-bridge';
// import Div from "@vkontakte/vkui/dist/components/Div/Div";
// import Index from "./panels/Index";
// import {isArraysIsEqu} from "./functions";
// import {Icon56CheckCircleOutline, Icon56CancelCircleOutline, Icon56ErrorOutline} from '@vkontakte/icons';

// CSS
import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

// VK UI COMPONENTS
import {
    Button,
    Group,
    ModalPage,
    ModalRoot,
    Panel,
    PanelHeader,
    View,
    Placeholder,
    Separator,
    PanelHeaderBack,
    Header,
    RichCell,
    Avatar,
    SliderSwitch,
    Tabs,
    TabsItem,
    Title,
    Div,
    PanelHeaderButton,
    Snackbar, Footer, Counter, PullToRefresh
} from '@vkontakte/vkui';

// Icons
import {Icon28MasksOutline, Icon56Stars3Outline} from '@vkontakte/icons';
import {Icon16Mask, Icon16Done} from '@vkontakte/icons';
import {Icon32MasksOutline} from '@vkontakte/icons';
import {Icon28RefreshOutline} from '@vkontakte/icons';

// My
import config from "./config";
import {pmoney, isBonusAvalible, parse_str, usePar, sleep} from "./functions";
import {MainContext} from "./context";

const testing = config.testing;

function Scam({id}) {
    const mainContext = useContext(MainContext);

    const [tabPage, setTabPage] = useState("mamonts");

    // const [placeHolder, setPlaceHolder] = useState({notify: "undefined"});
    // const placeHolderRef = useRef();
    //
    // placeHolderRef.current = {placeHolder, setPlaceHolder};

    let content = null;

    const [isFetching, setFetching] = usePar(mainContext, "isFetching", false);

    function activateFetching() {
        setFetching(true);
        mainContext.set();
    }

    async function updating() {
        const minTime = 600;
        const start = Date.now();

        async function extraFunc() {
            const current = Date.now();
            if (current - start < minTime) {
                await sleep(minTime - (current - start));
            }
        }

        setFetching(false);
        await mainContext.updateScam(mainContext, true, extraFunc);
    }

    if (isFetching) {
        console.log("updating scam...");
        updating();
    }

    function openScamLinkModal() {
        const modal = {id: "scam"};
        mainContext.setActiveModal(modal);
        mainContext.set();
    }

    const snackBar = <Snackbar
        onClose={() => {
            mainContext.setScamSnackBar(false);
            mainContext.set();
        }}
        before={<Avatar size={24} style={{background: 'var(--accent)'}}><Icon16Done fill="#fff" width={14}
                                                                                    height={14}/></Avatar>}
    >
        Ссылка скопирована
    </Snackbar>;

    if (mainContext.scam) {
        let mamonts, counter;
        if (mainContext.scam.mamonts_count == 0) {
            counter = null;

            mamonts = <Placeholder
                header="ты ещё не заскамил ни одного мамонта"
                style={{verticalAlign: "middle"}}
            >
                получи свою скам-ссылку, и когда по ней перейдёт человек, не играющий в бота, он будет
                заскамлен.<br/><br/>ты получишь с этого рандомное количество денег.
            </Placeholder>;
        } else {
            let counterClassName = `bandit-scam-counter${tabPage == "mamonts" ? " bandit-scam-counter-active" : ""}`;
            counter = <Counter
                size="s"
                mode="secondary"
                className={counterClassName}
            >
                <span style={{position: "relative", top: 2}}>
                    {mainContext.scam.mamonts_count}
                </span>
            </Counter>

            mamonts = <div>{mainContext.scam.mamonts.map((value, index) => {
                let vkName, vkAva, vkSex;

                if (testing) {
                    vkName = "Смачаная Бибра";
                    vkAva = false;
                    vkSex = 1;
                } else {
                    vkName = `${mainContext.vk_players[value.id].first_name} ${mainContext.vk_players[value.id].last_name}`;
                    vkAva = mainContext.vk_players[value.id].photo_100;
                    vkSex = Math.max(0, mainContext.vk_players[value.id].sex - 1);
                }

                return <RichCell
                    key={index}
                    before={<Avatar size={48} src={vkAva}/>}
                    caption={config.scamReasons[vkSex][value.type]}
                    after={<span style={{color: "var(--button_commerce_background)"}}>+{pmoney(value.sum)}</span>}
                    href={`https://vk.com/id${value.id}`}
                >
                    {vkName}
                </RichCell>
            })}</div>;
        }

        const scamers = <div>{mainContext.scam.top.map((value, index) => {
            let vkName, vkAva;

            if (testing) {
                vkName = "Смачаная Бибра";
                vkAva = false;
            } else {
                vkName = `${mainContext.vk_players[value.id].first_name} ${mainContext.vk_players[value.id].last_name}`;
                vkAva = mainContext.vk_players[value.id].photo_100;
            }

            return <RichCell
                key={index}
                before={<Avatar size={48} src={vkAva}/>}
                caption={`всего заработано — ${pmoney(value.sum)}`}
                href={`https://vk.com/id${value.id}`}
            >
                {`${value.top}. ${vkName}`}
            </RichCell>
        })}
            {/*<Footer>список обновляется каждые 10 минут</Footer>*/}
        </div>;

        let tabElement;
        switch (tabPage) {
            case "mamonts":
                tabElement = mamonts;
                break;
            case "scamers":
                tabElement = scamers;
                break;
        }

        content = <div>
            <PanelHeader
                left={<PanelHeaderBack onClick={() => mainContext.goBackNow()}/>}
            >
                скам
            </PanelHeader>
            <PullToRefresh onRefresh={activateFetching} isFetching={isFetching}>
                <Group>
                    <Header mode="secondary" style={{padding: 0}}>
                        <div style={{textAlign: "center", width: document.documentElement.clientWidth}}>всего заработано
                            на
                            скаме
                        </div>
                    </Header>
                    <Title level="1" weight="bold" style={{
                        marginBottom: 4,
                        textAlign: "center",
                        fontSize: 36
                    }}>{mainContext.scam.scam_sum ? pmoney(mainContext.scam.scam_sum) : "$0"}</Title>
                    <Header mode="secondary" style={{padding: 0}}>
                        <div style={{textAlign: "center", width: document.documentElement.clientWidth}}>работа</div>
                    </Header>
                    <Div style={{display: "flex", paddingTop: 0}}>
                        <Button
                            size="m"
                            stretched
                            style={{marginRight: 6}}
                            mode="primary"
                            before={<Icon16Mask/>}
                            onClick={() => {
                                openScamLinkModal();
                            }}
                        >скам-ссылка</Button>
                        <Button
                            size="m"
                            stretched
                            style={{marginLeft: 6}}
                            mode="outline"
                            href="https://vk.com/@botbandit-skam"
                        >информация</Button>
                    </Div>
                </Group>
                {/*<Group header={<Header mode="secondary">список мамонтов</Header>}>*/}
                <Group>
                    <Tabs>
                        <TabsItem
                            selected={tabPage == "mamonts"}
                            onClick={() => setTabPage("mamonts")}
                        >
                    <span>
                        <span className="bandit-scam-menu-bar">{mainContext.scam.mamonts_count < 100 ? "Твои мамонты" : "Мамонты"}</span>
                        {counter}
                    </span>
                        </TabsItem>
                        <TabsItem
                            selected={tabPage == "scamers"}
                            onClick={() => setTabPage("scamers")}
                        >
                    <span>
                        <span className="bandit-scam-menu-bar">Топ скамеров</span>
                    </span>
                        </TabsItem>
                    </Tabs>
                    {tabElement}
                </Group>
            </PullToRefresh>

            {mainContext.scamSnackBar && snackBar}
        </div>;
    }

    console.log(content);

    return <Panel id={id}>{content}</Panel>;
}

export default Scam;